"use client";

import { useState } from "react";
import { toast } from "sonner";
import { useStore } from "zustand";

import { LoadingButton } from "~/components/loading-button";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { saveTaskInstanceResult } from "~/server/actions/tasks";

import { PostSubmit } from "../shared";
import { useWord2TaskStore } from "./provider";
import { RenderWord2Image } from "./render-word2-image";

export function ExamView({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) {
  const Store = useWord2TaskStore();
  const { task, setAnswer, preview, answers } = useStore(Store, (s) => ({
    task: s.computed.task,
    answers: s.answers,
    setAnswer: s.setAnswer,
    preview: s.preview,
  }));
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit() {
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: "WORD2",
      view: "EXAM",
      leftColumnIds: task.leftColumnIds,
      rightColumnIds: task.rightColumnIds,
      answers: Object.values(answers),
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      setLoading(false);
      logger.error(res.error);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId,
      groupId,
    });
    setDone(true);
    setLoading(false);
  }

  return (
    <div>
      {done ? (
        <PostSubmit groupId={groupId} preview={preview} isSeb taskType="EXAM" />
      ) : (
        <>
          <Table className="w-full">
            <TableHeader>
              <TableRow className="bg-secondary">
                <TableHead className="w-8"></TableHead>
                <TableHead className="text-foreground font-bold">
                  {task.leftTitle}
                </TableHead>
                <TableHead className="text-foreground font-bold">
                  {task.rightTitle}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {task.pairs.map((pair, i) => (
                <TableRow key={pair.id}>
                  <TableCell>
                    <div className="items-top flex">#{i + 1}</div>
                  </TableCell>
                  <TableCell>
                    {pair.left.map((item, index) => (
                      <div
                        key={index}
                        className="mx-auto max-w-3xl whitespace-break-spaces py-2 text-2xl font-bold"
                      >
                        {item.type === "image" ? (
                          <RenderWord2Image
                            item={item}
                            alt="Bild"
                            width={600 / 4}
                            height={400 / 4}
                            className="max-w-full p-2"
                          />
                        ) : (
                          <h2>{item.word}</h2>
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Input
                      onChange={(e) =>
                        setAnswer({
                          id: pair.id,
                          data: { word: e.target.value },
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex flex-row justify-end">
            <LoadingButton
              onClick={handleSubmit}
              isLoading={loading}
              disabled={preview}
            >
              <Translation id="common.submit">Skicka in</Translation>
            </LoadingButton>
          </div>
        </>
      )}
    </div>
  );
}
