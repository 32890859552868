import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import { type User } from "lucia";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { env } from "~/env.mjs";
import { type TaskTypeEnum } from "~/lib/baseTaskSettings";
import { type Media } from "~/payload-types";
import { type TaskInstanceSelectSchema } from "~/server/zod/tasks";

import { replaceMultipleSlashes } from "./strings";

require("dayjs/locale/sv");

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface Navigation {
  href: string;
  label: string;
  icon?: string;
  exactMatch?: boolean;
  external?: boolean;
}

interface ObjectWithHref {
  href: string;
}

export function prefixLinks<T extends ObjectWithHref>(
  prefix: string,
  navigation: T[],
) {
  return navigation.map((nav) => ({
    ...nav,
    href: replaceMultipleSlashes(`/${prefix}/${nav.href}`),
  }));
}

export function formatDate(input: string | number | Date): string {
  const date = new Date(input);
  return date.toLocaleDateString("sv-SE", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path: string) {
  return `${env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ActionName = z.string().refine(
  (input) => {
    const dotCount = input.split(".").length - 1;
    return dotCount >= 2;
  },
  {
    message:
      "Action name must contain at least two dots. (scope.target.action)",
  },
);

export function getImageUrl(media: Media | null | undefined | number) {
  if (!media || typeof media === "number") {
    return null;
  }
  return `${env.NEXT_PUBLIC_PAYLOAD_URL}${media.url}`;
}

export function getImageBlurhash(media: Media | null | undefined | number) {
  if (!media || typeof media === "number") {
    return undefined;
  }
  return media.blurhash;
}

export function generateTitle(string: string): string {
  // "mån", "tis", "ons", "tor", "fre", "lör", "sön" + 5 + dec + 2023
  const date = dayjs().locale("sv").format("ddd D MMM YYYY");
  return string.replace("{{date}}", date);
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export const emptyTaskInstance: TaskInstanceSelectSchema & {
  isSeb: boolean;
} = {
  createdAt: new Date(),
  id: "0",
  taskId: "0",
  updatedAt: null,
  groupId: 0,
  groupCategoryId: "0",
  sortIndex: 0,
  removed: false,
  isFocused: false,
  password: null,
  isSeb: false,
  settings: {},
};

export const emptyUser: User = {
  name: "",
  givenName: "",
  familyName: "",
  email: "",
  picture: "",
  role: "UNKNOWN",
  emailVerified: false,
  hasTwoFactor: false,
  onboarded: false,
  subjects: null,
  userId: "0",
  canBeMerged: false,
  skolonUserId: null,
  hiddenLanguages: [],
};

export function getTaskInstanceLink({
  groupId,
  taskInstanceId,
  type,
}: {
  type: TaskTypeEnum;
  groupId: number;
  taskInstanceId: string;
}): string | null {
  switch (type) {
    case "AICHAT":
      return `/larare/${groupId}/chattar/${taskInstanceId}`;

    case "EXIT":
      return `/larare/${groupId}/exit-tickets/${taskInstanceId}`;

    case "ICEBREAKER":
      return `/larare/${groupId}/icebreakers/${taskInstanceId}`;

    case "AIGRADER":
      return `/larare/${groupId}/ai-grader/${taskInstanceId}`;

    case "WORD":
      return `/larare/${groupId}/uppgifter/word/${taskInstanceId}`;
    case "WORD2":
      return `/larare/${groupId}/uppgifter/word2/${taskInstanceId}`;

    case "CLOZE":
    case "DICTATION":
    case "EXAM":
    case "SCATTERGORIES":
      return null;
  }
}

export function getTaskInstanceResultLink({
  groupId,
  taskInstanceId,
  type,
  userId,
  taskResultId,
}: {
  type: TaskTypeEnum;
  groupId: number;
  taskInstanceId: string;
  userId: string;
  taskResultId: string;
}): string | null {
  switch (type) {
    case "EXIT":
      return `/larare/${groupId}/exit-tickets/${taskInstanceId}#${userId}`;
    case "AIGRADER":
      return `/larare/${groupId}/ai-grader/${taskInstanceId}/${taskResultId}`;
    case "WORD":
      return `/larare/${groupId}/uppgifter/word/${taskInstanceId}/${userId}`;
    case "WORD2":
      return `/larare/${groupId}/uppgifter/word2/${taskInstanceId}/${userId}`;
    case "AICHAT":
      return `/larare/${groupId}/chattar/${taskInstanceId}/${userId}`;
    case "ICEBREAKER":
      return `/larare/${groupId}/icebreakers/${taskInstanceId}#${userId}`;

    case "CLOZE":
    case "DICTATION":
    case "EXAM":
    case "SCATTERGORIES":
      return null;
  }
}
