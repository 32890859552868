import { Info, InfoIcon } from "lucide-react";
import React from "react";

import { cn } from "~/lib/utils";

import { Avatar } from "../avatar";
import { Button, buttonVariants } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { type Bot } from "./chat";

interface ChatTopbarProps {
  bot: Bot;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
}

export const TopbarIcons = [{ icon: Info }];

export default function ChatTopbar({
  children,
  bot,
  subtitle,
}: ChatTopbarProps) {
  return (
    <div className="flex h-20 w-full items-center justify-between border-b p-4">
      <div className="flex items-center gap-2">
        <Avatar
          className="flex items-center justify-center"
          seed={bot.seed}
          initials="bot"
          avatarClassName="h-10 w-10"
        />
        <div className="flex flex-col">
          <span className="font-medium">{bot.name}</span>
          <span className="text-xs">{subtitle}</span>
        </div>
      </div>

      <div>
        {children && (
          <Dialog>
            <DialogTrigger asChild>
              <button
                className={cn(
                  buttonVariants({ variant: "ghost", size: "icon" }),
                  "h-9 w-9",
                  "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
                )}
              >
                <InfoIcon size={20} className="text-muted-foreground" />
              </button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Chattinfo</DialogTitle>
              </DialogHeader>
              {children}
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="submit">Stäng</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </div>
  );
}
