import { z } from "zod";

const Text = z.object({
  type: z.literal("text").default("text"),
  text: z.string(),
  format: z.number(),
});

const Linebreak = z.object({
  type: z.literal("linebreak").default("linebreak"),
});

const ListItem = z.object({
  type: z.literal("listitem").default("listitem"),
  children: z.array(z.union([Text, Linebreak])),
});

const List = z.object({
  type: z.literal("list").default("list"),
  tag: z.enum(["ol", "ul"]),
  listType: z.enum(["bullet", "number"]),
  children: z.array(ListItem),
});

const Heading = z.object({
  type: z.literal("heading").default("heading"),
  version: z.number(),
  tag: z.enum(["h1", "h2", "h3", "h4", "h5"]),
  children: z.array(Text),
});

const Paragraph = z.object({
  type: z.literal("paragraph").default("paragraph"),
  children: z.array(Text),
  format: z.string(),
});

const Children = z.discriminatedUnion("type", [
  Text,
  Linebreak,
  List,
  Heading,
  Paragraph,
]);

export const ExamRoot = z.object({
  root: z.object({
    children: z.array(Children),
    type: z.literal("root").default("root"),
  }),
});

export type ExamRoot = z.infer<typeof ExamRoot>;
