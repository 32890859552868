import { cn } from "~/lib/utils";

import { AvatarFallback, AvatarImage, Avatar as BaseAvatar } from "./ui/avatar";

interface Props {
  image?: string;
  initials: string;
  className?: string;
  avatarClassName?: string;
  seed?: string;
  title?: string;
}

const avatarBackgrounds = [
  "bg-red-200 dark:bg-red-800",
  "bg-yellow-200 dark:bg-yellow-800",
  "bg-green-200 dark:bg-green-800",
  "bg-blue-200 dark:bg-blue-800",
  "bg-indigo-200 dark:bg-indigo-800",
  "bg-purple-200 dark:bg-purple-800",
  "bg-pink-200 dark:bg-pink-800",
];

function getRandomBackground(seed: string) {
  const index = seed
    .split("")
    .map((char) => char.charCodeAt(0))
    .reduce((acc, curr) => acc + curr, 0);
  return avatarBackgrounds[index % avatarBackgrounds.length];
}

export function Avatar({
  image,
  initials,
  className,
  seed,
  avatarClassName,
  title,
}: Props) {
  return (
    <BaseAvatar className={className} title={title}>
      {seed ? (
        <AvatarImage
          className={cn(avatarClassName, getRandomBackground(seed))}
          src={`https://api.dicebear.com/8.x/adventurer/svg?seed=${seed}`}
        />
      ) : null}
      {image ? (
        <AvatarImage className={avatarClassName} src={image} alt={initials} />
      ) : null}
      <AvatarFallback
        className={cn(avatarClassName, getRandomBackground(initials))}
      >
        {initials}
      </AvatarFallback>
    </BaseAvatar>
  );
}
