export const config = {
  links: {
    integrityPolicy: "/privacy-policy",
    termsOfService: "/terms-of-service",
  },
};

export const MAX_AUDIO_GENERATION_LENGTH = 1024;

export const WORD2_AUDIO_LICENSE_TOOL_ID = "PREMIUM" as const;
export const WORD2_AUDIO_MAX_LENGTH = 75;
