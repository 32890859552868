import {
  boolean,
  index,
  integer,
  jsonb,
  pgEnum,
  serial,
  timestamp,
  varchar,
} from "drizzle-orm/pg-core";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import { COLORS } from "~/lib/colors";

import { pgTable, pgTypeId } from "../typeid";

export const taskPgEnum = pgEnum("colors_enum", COLORS);

export const groups = pgTable("groups", {
  id: serial("id").primaryKey(),
  name: varchar("name", { length: 256 }).notNull(),
  subject: varchar("subject", { length: 8 }).notNull().default("UNKNOWN"),
  demo: boolean("demo").notNull().default(false),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
  archived: boolean("archived").notNull().default(false),
  color: taskPgEnum("color").notNull().default("default"),
  sortIndex: integer("sort_index").notNull().default(0),
  skolonGroupId: varchar("skolon_group_id").unique(),
});

export const groupSelectSchema = createSelectSchema(groups);

export type GroupSelectSchema = z.infer<typeof groupSelectSchema>;

export const groupInsertSchema = createInsertSchema(groups).omit({
  id: true,
});

export type GroupInsertSchema = z.infer<typeof groupInsertSchema>;

export const groupId = integer("group_id")
  .notNull()
  .references(() => groups.id, {
    onDelete: "cascade",
  });

const groupDivisionGroupSchema = z.object({
  name: z.string(),
  members: z.array(z.string()),
});

export const groupDivisionSchema = z.array(groupDivisionGroupSchema);

export type GroupDivision = z.infer<typeof groupDivisionSchema>;

export const groupDivision = pgTable(
  "group_division",
  {
    id: pgTypeId("id").primaryKey(),
    division: jsonb("division").$type<GroupDivision>().notNull(),
    groupId: integer("group_id")
      .notNull()
      .references(() => groups.id, {
        onDelete: "cascade",
      }),
    createdAt: timestamp("created_at").defaultNow().notNull(),
    updatedAt: timestamp("updated_at").defaultNow(),
  },
  (t) => ({
    groupIdx: index("group_division_group_idx").on(t.groupId),
  }),
);
