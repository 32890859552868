import { boolean, integer, timestamp, varchar } from "drizzle-orm/pg-core";

import { pgTable, pgTypeId } from "../typeid";
import { groupId } from "./groups";

export const groupCategories = pgTable("group_categories", {
  id: pgTypeId("gc").primaryKey(),
  groupId,
  name: varchar("name", { length: 256 }).notNull(),
  public: boolean("public").notNull().default(false),
  defaultCollapsed: boolean("default_collapsed").notNull().default(true),
  sortIndex: integer("sort_index").notNull().default(0),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});
