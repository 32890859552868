import { type LucideProps } from "lucide-react";
import * as React from "react";

import { Icons } from "~/components/icons";
import { cn } from "~/lib/utils";

type EmptyPlaceholderProps = React.HTMLAttributes<HTMLDivElement>;

export function EmptyPlaceholder({
  className,
  children,
  ...props
}: EmptyPlaceholderProps) {
  return (
    <div
      className={cn(
        "animate-in fade-in-50 bg-background flex flex-col items-center justify-center rounded-md border border-dashed p-8 text-center md:min-h-[400px]",
        className,
      )}
      {...props}
    >
      <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
        {children}
      </div>
    </div>
  );
}

interface EmptyPlaceholderIconProps
  extends Partial<React.SVGProps<SVGSVGElement>> {
  name: keyof typeof Icons;
}

EmptyPlaceholder.Icon = function EmptyPlaceHolderIcon({
  name,
  className,
  ...props
}: EmptyPlaceholderIconProps) {
  const Icon = Icons[name];

  if (!Icon || typeof Icon === "string") {
    return null;
  }

  return (
    <div className="bg-muted flex h-20 w-20 items-center justify-center rounded-full">
      <Icon
        className={cn("h-10 w-10", className)}
        // Add type assertion to Icon props
        {...(props as LucideProps)}
      />
    </div>
  );
};

type EmptyPlacholderTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

EmptyPlaceholder.Title = function EmptyPlaceholderTitle({
  className,
  ...props
}: EmptyPlacholderTitleProps) {
  return (
    <h2 className={cn("mt-6 text-xl font-semibold", className)} {...props} />
  );
};

type EmptyPlacholderDescriptionProps =
  React.HTMLAttributes<HTMLParagraphElement>;

EmptyPlaceholder.Description = function EmptyPlaceholderDescription({
  className,
  ...props
}: EmptyPlacholderDescriptionProps) {
  return (
    <div
      className={cn(
        "text-muted-foreground mb-8 mt-2 text-center text-sm font-normal leading-6",
        className,
      )}
      {...props}
    />
  );
};
