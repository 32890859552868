import { env } from "~/env.mjs";

interface HasRole {
  role: string;
}

export function isStudent<T extends HasRole>(item: T): boolean {
  if (env.NEXT_PUBLIC_NODE_ENV === "development") {
    return true;
  }
  return item.role === "STUDENT";
}

export function removeNulls<T>(array: (T | null)[]): T[] {
  return array.filter((item): item is T => item !== null);
}

export function removeNullsAndUndefined<T>(
  array: (T | null | undefined)[],
): T[] {
  return array.filter((item): item is T => item !== null && item !== undefined);
}

export function uniqueOnly<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}
