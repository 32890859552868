import { z } from "zod";

export const COLORS = [
  "default",
  "red",
  "yellow",
  "green",
  "blue",
  "indigo",
  "lavender",
  "cottonCandy",
  "babyBlue",
  "clementine",
  "salmon",
  "forestGreen",
  "mint",
] as const;

export const colors: Record<(typeof COLORS)[number], string> = {
  default: "bg-background",
  red: "bg-red-200 dark:bg-red-800",
  yellow: "bg-yellow-200 dark:bg-yellow-800",
  green: "bg-green-200 dark:bg-green-800",
  blue: "bg-blue-200 dark:bg-blue-800",
  indigo: "bg-indigo-200 dark:bg-indigo-800",
  lavender: "bg-[#D9BCF2] dark:bg-[#A08AF2]",
  cottonCandy: "bg-[#F2C4DE] dark:bg-[#F2A8D1]",
  babyBlue: "bg-[#AED8F2] dark:bg-[#7F9FBF]",
  clementine: "bg-[#F2A477] dark:bg-[#D17F50]",
  salmon: "bg-[#FFB6A3] dark:bg-[#D1847F]",
  forestGreen: "bg-[#365359] dark:bg-[#263D40] text-white",
  mint: "bg-[#D4F5DD] dark:bg-[#A3D0B3]",
};

export const colorNames: Record<(typeof COLORS)[number], string> = {
  default: "Default",
  red: "Red",
  yellow: "Yellow",
  green: "Green",
  blue: "Blue",
  indigo: "Indigo",
  lavender: "Lavender",
  cottonCandy: "Cotton Candy",
  babyBlue: "Baby Blue",
  clementine: "Clementine",
  salmon: "Salmon",
  forestGreen: "Forest Green",
  mint: "Mint",
};

export const ColorsEnum = z.enum(COLORS).default("default");
export type ColorsEnum = z.infer<typeof ColorsEnum>;
