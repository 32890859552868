"use client";

import { type LucideIcon, PencilIcon, SquareStackIcon } from "lucide-react";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";
import { cn } from "~/lib/utils";

import { type ViewName, useWord2TaskStore } from "./provider";
import { Settings } from "./settings";
import { WordTable } from "./word-table";

export const NoViewSelected = () => {
  const Store = useWord2TaskStore();
  const {
    view,
    setView,
    words,
    leftColumnIds,
    rightColumnIds,
    preferVoiceOnly,
    setPreferVoiceOnly,
    canVoiceOnly,
  } = useStore(Store, (s) => ({
    view: s.view,
    setView: s.setView,
    words: s.computed.task.pairs,
    leftColumnIds: s.leftColumnIds,
    rightColumnIds: s.rightColumnIds,
    preferVoiceOnly: s.preferVoiceOnly,
    setPreferVoiceOnly: s.setPreferVoiceOnly,
    canVoiceOnly: s.computed.canVoiceOnly,
  }));

  if (view) {
    return null;
  }
  const hasRightAndLeftColumns =
    leftColumnIds.length > 0 && rightColumnIds.length > 0;
  const views: Record<
    string,
    {
      name: React.ReactNode;
      Icon: LucideIcon;
      disabled: boolean;
      testId: string;
    }
  > = {
    SPELL: {
      name: <Translation id="task.word.spell">Skriv orden</Translation>,
      Icon: PencilIcon,
      disabled: words.every((w) => w.right.every((r) => r.type === "image")),
      testId: "view-spell",
    },
    QUIZ: {
      name: <Translation id="task.word.quiz">Fyrvalsalternativ</Translation>,
      Icon: SquareStackIcon,
      disabled: false,
      testId: "view-quiz",
    },
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="mx-auto my-2 grid w-full grid-cols-1 justify-center gap-2 md:my-0 md:grid-cols-3">
        {Object.entries(views).map(([viewName, view]) => (
          <button
            key={viewName}
            className={cn(
              "bg-background relative mx-1 overflow-hidden rounded-lg border p-2 text-left shadow transition-shadow hover:shadow-lg",
              "disabled:cursor-not-allowed disabled:opacity-25",
            )}
            onClick={() => {
              setView(viewName as ViewName);
            }}
            disabled={!hasRightAndLeftColumns || view.disabled}
            data-testid={view.testId}
          >
            <div className="flex flex-row items-center justify-start gap-2 rounded-md p-2 md:flex-col md:items-start md:justify-around md:p-6">
              <view.Icon className="h-8 w-8 md:h-12 md:w-12" />
              <div className="space-y-2">
                <h3 className="font-bold">{view.name}</h3>
              </div>
              {canVoiceOnly && (
                <div
                  className="flex items-center space-x-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Switch
                    id="voice-only"
                    checked={preferVoiceOnly}
                    onCheckedChange={setPreferVoiceOnly}
                  />
                  <Label htmlFor="voice-only">
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.voice-only">
                      Endast röst
                    </Translation>
                  </Label>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
      <div className="grid auto-rows-auto grid-cols-1 gap-2 md:grid-cols-5">
        <div className="order-3 col-span-1 md:order-1 md:col-span-3">
          <div className="bg-background h-auto w-full max-w-full overflow-hidden overflow-x-auto rounded-xl border shadow-lg">
            <WordTable />
          </div>
        </div>
        <div className="order-2 col-span-1 md:col-span-2">
          <div className="bg-background h-auto overflow-hidden rounded-xl border p-2 shadow-lg">
            <Settings />
          </div>
        </div>
      </div>
    </div>
  );
};
