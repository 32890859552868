import { ExternalLinkIcon } from "lucide-react";

import { EmptyPlaceholder } from "~/components/empty-placeholder";
import { type ToolKey } from "~/lib/licenses";

import { Translation } from "./translations/component";
import { Button } from "./ui/button";
import { DialogHeader, DialogTitle } from "./ui/dialog";

interface Props {
  tool: ToolKey;
}

export function PremiumPlaceholder({ tool }: Props) {
  return (
    <div className="container max-w-2xl pt-2">
      <EmptyPlaceholder>
        <EmptyPlaceholder.Icon name="crown" />
        <EmptyPlaceholder.Title>
          <Translation id="premium-placeholder.title">
            Licens saknas
          </Translation>
        </EmptyPlaceholder.Title>
        <EmptyPlaceholder.Description>
          <div className="flex flex-col gap-4">
            <p className="text-muted-foreground text-sm">
              <Translation id="premium-placeholder.description">
                Du har inte tillgång till detta verktyget eftersom du saknar en
                licens. Kontakta din administratör eller Nextgen Classroom för
                mer information.
              </Translation>
            </p>
            <a href={`/license-${tool.toLowerCase()}`} target="_blank">
              <Button variant="link">
                <Translation id="premium-placeholder.link">Läs mer</Translation>
                <ExternalLinkIcon className="ml-2 h-4 w-4" />
              </Button>
            </a>
            <p className="text-muted-foreground text-xs">License ID: {tool}</p>
          </div>
        </EmptyPlaceholder.Description>
      </EmptyPlaceholder>
    </div>
  );
}

export function PremiumDialogPlaceholder({ tool }: Props) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>
          <Translation id="premium-placeholder.title">
            Licens saknas
          </Translation>
        </DialogTitle>
      </DialogHeader>
      <div className="mt-4 flex flex-col gap-4 text-center">
        <p className="text-muted-foreground text-sm">
          <Translation id="premium-placeholder.description">
            Du har inte tillgång till detta verktyget eftersom du saknar en
            licens. Kontakta din administratör eller Nextgen Classroom för mer
            information.
          </Translation>
        </p>
        <a href={`/license-${tool.toLowerCase()}`} target="_blank">
          <Button variant="link">
            <Translation id="premium-placeholder.link">Läs mer</Translation>
            <ExternalLinkIcon className="ml-2 h-4 w-4" />
          </Button>
        </a>
        <p className="text-muted-foreground text-xs">License ID: {tool}</p>
      </div>
    </>
  );
}
