import { relations } from "drizzle-orm";
import {
  bigint,
  boolean,
  index,
  pgEnum,
  serial,
  timestamp,
  varchar,
} from "drizzle-orm/pg-core";
import { createSelectSchema } from "drizzle-zod";

import { pgTable, pgTypeId } from "../typeid";
import { taskInstances } from "./tasks";
import { userRoles } from "./userRoles";

export const users = pgTable(
  "auth_user",
  {
    id: varchar("id", {
      length: 32,
    }).primaryKey(),
    email: varchar("email", { length: 256 }),
    email_verified: boolean("email_verified").default(false).notNull(),
    role: userRoles("role").default("STUDENT").notNull(),
    name: varchar("name").notNull(),
    given_name: varchar("given_name").notNull(),
    family_name: varchar("family_name").notNull(),
    picture: varchar("picture").notNull(),
    onboarded: boolean("onboarded").default(false).notNull(),
    subjects: varchar("subjects"),
    primary_subject: varchar("primary_subject"),
    last_login: timestamp("last_login").defaultNow(),
    demo: boolean("demo").default(false).notNull(),
    newsletter: boolean("newsletter").default(false).notNull(),
    two_factor_secret: varchar("two_factor_secret"),
    removed: boolean("removed").default(false).notNull(),
    skolon_user_id: varchar("skolon_user_id").unique(),
    can_be_merged: boolean("can_be_merged").default(false).notNull(),
    hidden_languages: varchar("hidden_languages").default("").notNull(),
  },
  (table) => {
    return {
      demoIdx: index("demo_idx").on(table.demo),
    };
  },
);

export const userId = varchar("user_id", {
  length: 32,
})
  .notNull()
  .references(() => users.id);

export const selectUserSchema = createSelectSchema(users);

export const userRoleEnum = selectUserSchema.shape.role;

export const session = pgTable("user_session", {
  id: varchar("id", {
    length: 128,
  }).primaryKey(),
  userId,
  activeExpires: bigint("active_expires", {
    mode: "number",
  }).notNull(),
  idleExpires: bigint("idle_expires", {
    mode: "number",
  }).notNull(),
  needs_two_factor_verification: boolean("needs_two_factor_verification"),
  is_safe_exam_browser: boolean("is_safe_exam_browser"),
  is_impersonating: boolean("is_impersonating"),
});

export const key = pgTable("user_key", {
  id: varchar("id", {
    length: 255,
  }).primaryKey(),
  userId,
  hashedPassword: varchar("hashed_password", {
    length: 255,
  }),
});

export const emailVerificationToken = pgTable("email_verification_token", {
  id: varchar("id", {
    length: 255,
  }).primaryKey(),
  expires: bigint("expires", {
    mode: "number",
  }).notNull(),
  userId,
});

export const passwordResetToken = pgTable("password_reset_token", {
  id: varchar("id", {
    length: 255,
  }).primaryKey(),
  expires: bigint("expires", {
    mode: "number",
  }).notNull(),
  userId,
});

export const sebTokens = pgTable("seb_tokens", {
  id: varchar("id", {
    length: 255,
  }).primaryKey(),
  token: varchar("token", { length: 256 }).notNull(),
  userId: varchar("user_id")
    .notNull()
    .references(() => users.id, {
      onDelete: "cascade",
    }),
  ipAddress: varchar("ip_address", { length: 39 }).notNull(),
  browserUserAgent: varchar("browser_user_agent", { length: 512 }).notNull(),
  usedAt: timestamp("used_at"),
  // TODO: Make not null
  taskInstanceId: pgTypeId("task_instance_id").references(
    () => taskInstances.id,
    {
      onDelete: "cascade",
    },
  ),
  expiresAt: timestamp("expires_at").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow().notNull(),
});

export const sebTokensRelations = relations(sebTokens, ({ one }) => ({
  user: one(users, {
    fields: [sebTokens.userId],
    references: [users.id],
  }),
}));

export const userEventPgEnum = pgEnum("user_event", ["LOGIN", "SIGNUP"]);

export const userEvents = pgTable("user_events", {
  id: serial("id").primaryKey(),
  userId,
  event: userEventPgEnum("event").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
});
