import { z } from "zod";

import {
  HighlightColors,
  type HighlightedColor,
} from "../../plugins/highlighted-text-node";

const Text = z.object({
  type: z.literal("text").default("text"),
  text: z.string(),
});

export const HighlightedText = z.object({
  type: z.literal("highlighted-text").default("highlighted-text"),
  text: z.string(),
  color: HighlightColors,
});

export type HighlightedText = z.infer<typeof HighlightedText>;

const Linebreak = z.object({
  type: z.literal("linebreak").default("linebreak"),
});

const Children = z.discriminatedUnion("type", [
  Text,
  Linebreak,
  HighlightedText,
]);

const Paragraph = z.object({
  type: z.literal("paragraph").default("paragraph"),
  children: z.array(Children),
});

export const HighlightedLexicalRoot = z.object({
  root: z.object({
    children: z.array(Paragraph),
    type: z.literal("root").default("root"),
  }),
});

export type HighlightedLexicalRoot = z.infer<typeof HighlightedLexicalRoot>;

export function createHighlightedTextNodeTree(
  text: string,
  color: HighlightedColor,
): HighlightedLexicalRoot {
  return {
    root: {
      children: [
        {
          children: [
            {
              text,
              type: "highlighted-text",
              color,
            },
          ],
          type: "paragraph",
        },
      ],
      type: "root",
    },
  };
}
