import { EarIcon } from "lucide-react";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { Checkbox } from "~/components/ui/checkbox";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";

import { useWord2TaskStore } from "./provider";
import { Word2ColumnEmoji } from "./use-task-languages";

export function Settings() {
  const Store = useWord2TaskStore();
  const {
    flipped,
    setFlipped,
    deburred,
    setDeburred,
    preferVoiceOnly,
    setPreferVoiceOnly,
    ignoreWhiteSpace,
    setIgnoreWhiteSpace,
  } = useStore(Store, (s) => ({
    flipped: s.flipped,
    setFlipped: s.setFlipped,
    deburred: s.deburred,
    setDeburred: s.setDeburred,
    preferVoiceOnly: s.preferVoiceOnly,
    setPreferVoiceOnly: s.setPreferVoiceOnly,
    ignoreWhiteSpace: s.ignoreWhiteSpace,
    setIgnoreWhiteSpace: s.setIgnoreWhiteSpace,
  }));
  return (
    <div className="max-w-[500px] space-y-4">
      <h2 className="text-2xl font-bold">
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.title">
          Inställningar
        </Translation>
      </h2>
      <ColumnsPicker />
      {/* flipped */}
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.change-direction">
              Byt håll på språken
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.change-direction-description">
              Istället för att se t ex svenska till engelska så ser du engelska
              till svenska.
            </Translation>
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={flipped}
          onCheckedChange={(flipped) => setFlipped(flipped)}
        />
      </div>
      {/* preferVoiceOnly */}
      <div className="hidden flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.voice-only">
              Endast röst
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.voice-only-description">
              Om det finns möjlighet att lyssna på orden så kommer du endast få
              höra dem om detta är aktiverat.
            </Translation>
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={preferVoiceOnly}
          onCheckedChange={(preferVoiceOnly) =>
            setPreferVoiceOnly(preferVoiceOnly)
          }
        />
      </div>
      {/* deburred */}
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-accents">
              Ignorera accenter
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.no-need-for">
              Du behöver inte skriva t ex
            </Translation>{" "}
            <strong>á</strong>{" "}
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.enough-with">
              utan det räcker med
            </Translation>{" "}
            <strong>a</strong>.
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={deburred}
          onCheckedChange={(deburred) => setDeburred(deburred)}
        />
      </div>
      {/* ignoreWhiteSpace */}
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-spaces">
              Ignorera blanktecken
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-spaces-description">
              Det gör inget om eventuella mellanslag eller radbrytningar skiljer
              sig från det korrekta svaret.
            </Translation>
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={ignoreWhiteSpace}
          onCheckedChange={(ignoreWhiteSpace) =>
            setIgnoreWhiteSpace(ignoreWhiteSpace)
          }
        />
      </div>
    </div>
  );
}

function ColumnsPicker() {
  const Store = useWord2TaskStore();
  const {
    setLeftColumnsIds,
    leftColumnIds,
    setRightColumnsIds,
    rightColumnIds,
    allColumns,
    hiddenLanguages,
    setHiddenLanguages,
  } = useStore(Store, (s) => ({
    setLeftColumnsIds: s.setLeftColumnIds,
    leftColumnIds: s.leftColumnIds,
    setRightColumnsIds: s.setRightColumnIds,
    rightColumnIds: s.rightColumnIds,
    allColumns: s._task.data.columns,
    hiddenLanguages: s.hiddenLanguages,
    setHiddenLanguages: s.setHiddenLanguages,
  }));

  const leftColumns = allColumns.filter((column) => column.canBeLeft);
  const rightColumns = allColumns.filter((column) => column.canBeRight);

  return (
    <div className="flex flex-row items-center justify-around gap-2 space-y-2 rounded-lg border p-4 py-4">
      <div className="w-1/2">
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.left">
          Left
        </Translation>
        {leftColumns.map((column) => (
          <div className="flex items-center space-x-2" key={column.id}>
            <Checkbox
              id={`left-${column.id}`}
              checked={leftColumnIds.includes(column.id)}
              onCheckedChange={(checked) => {
                if (checked) {
                  if (column.type === "word") {
                    setHiddenLanguages(
                      hiddenLanguages.filter(
                        (lang) => lang !== column.language,
                      ),
                    );
                  }
                  setLeftColumnsIds([...leftColumnIds, column.id]);
                } else {
                  setLeftColumnsIds(
                    leftColumnIds.filter((id) => id !== column.id),
                  );
                }
              }}
            />
            <label
              htmlFor={`left-${column.id}`}
              className="flex flex-row items-center gap-2 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              <Word2ColumnEmoji column={column} className="h-5 w-5" />
              {column.label}
              {column.type === "word" && column.voices.length > 0 && (
                <EarIcon className="h-4 w-4 shrink-0" />
              )}
            </label>
          </div>
        ))}
      </div>
      <div className="w-1/2">
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.right">
          Right
        </Translation>
        {rightColumns.map((column) => (
          <div className="flex items-center space-x-2" key={column.id}>
            <Checkbox
              id={`right-${column.id}`}
              checked={rightColumnIds.includes(column.id)}
              onCheckedChange={(checked) => {
                if (checked) {
                  if (column.type === "word") {
                    setHiddenLanguages(
                      hiddenLanguages.filter(
                        (lang) => lang !== column.language,
                      ),
                    );
                  }
                  setRightColumnsIds([...rightColumnIds, column.id]);
                } else {
                  setRightColumnsIds(
                    rightColumnIds.filter((id) => id !== column.id),
                  );
                }
              }}
            />
            <label
              htmlFor={`right-${column.id}`}
              className="flex flex-row items-center gap-2 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              <Word2ColumnEmoji column={column} className="h-5 w-5" />
              {column.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
