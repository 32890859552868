"use client";

import { type ReactNode, createContext, useContext } from "react";

interface Value {
  tools: string[];
}

const UserAbilityContext = createContext<Value>({
  tools: [],
});

interface Props {
  value: Value;
  children: ReactNode;
}

export const UserAbilityProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <UserAbilityContext.Provider value={value}>
      {children}
    </UserAbilityContext.Provider>
  );
};

export const useUserAbility = () => {
  const context = useContext(UserAbilityContext);

  if (context === undefined) {
    throw new Error("useUserAbility must be used within a UserAbilityProvider");
  }

  return context;
};
