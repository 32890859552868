import {
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  TextNode,
} from "lexical";
import { z } from "zod";

export interface SerializedHighlightedTextNode extends SerializedTextNode {
  color: HighlightedColor;
}

export const highlightColors = [
  "yellow",
  "red",
  "green",
  "blue",
  "orange",
] as const;

export const HighlightColors = z.enum(highlightColors);

export type HighlightedColor = (typeof highlightColors)[number];

export const colorMap: Record<HighlightedColor, string[]> = {
  yellow: ["bg-yellow-100", "dark:bg-yellow-900"],
  red: ["bg-red-100", "dark:bg-red-900"],
  green: ["bg-green-100", "dark:bg-green-900"],
  blue: ["bg-blue-100", "dark:bg-blue-900"],
  orange: ["bg-orange-100", "dark:bg-orange-900"],
};

export class HighlightedTextNode extends TextNode {
  __color: HighlightedColor;

  constructor(text: string, color: HighlightedColor, key?: NodeKey) {
    super(text, key);
    this.__color = color;
  }

  exportJSON(): SerializedHighlightedTextNode {
    return {
      ...super.exportJSON(),
      color: this.__color,
      type: HighlightedTextNode.getType(),
    };
  }

  static importJSON(
    serializedNode: SerializedHighlightedTextNode,
  ): HighlightedTextNode {
    const node = new HighlightedTextNode(
      serializedNode.text,
      serializedNode.color,
    );
    return node;
  }

  static getType() {
    return "highlighted-text";
  }

  static clone(node: HighlightedTextNode) {
    return new HighlightedTextNode(node.__text, node.__color, node.__key);
  }

  createDOM(config: EditorConfig) {
    const dom = super.createDOM(config);
    const classList = colorMap[this.__color];
    if (!classList) {
      return dom;
    }
    dom.classList.remove(...dom.classList);
    dom.classList.add(...classList);
    dom.classList.add("highlighter-pen");
    return dom;
  }

  updateDOM(
    prevNode: HighlightedTextNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    if (prevNode.__color !== this.__color) {
      const classList = colorMap[this.__color];
      if (!classList) {
        return isUpdated;
      }
      dom.classList.remove(...dom.classList);
      dom.classList.add(...classList);
      dom.classList.add("highlighter-pen");
      return true;
    }
    return isUpdated;
  }
}

export function $createHighlightedTextNode(
  text: string,
  color: HighlightedColor,
): HighlightedTextNode | TextNode {
  if (text === "") {
    return new TextNode(text);
  }
  return new HighlightedTextNode(text, color);
}

export function $isHighlightedTextNode(
  node: LexicalNode | null | undefined,
): node is HighlightedTextNode {
  return node instanceof HighlightedTextNode;
}
