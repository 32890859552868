"use client";

import { type CreateMessage, type Message } from "ai";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { cn } from "~/lib/utils";

import { Avatar } from "../avatar";
import { type Bot, type User } from "./chat";

interface ChatListProps {
  messages?: (Message | CreateMessage)[];
  bot: Bot;
  user: User;
  studentInstructions?: string;
}

export function ChatList({
  messages,
  user,
  bot,
  studentInstructions,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={messagesContainerRef}
      className="flex h-full w-full flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <AnimatePresence>
        {studentInstructions && (
          <>
            <SystemMessage content="Tänk på att din undervisande lärare kan se hela konversationen." />
            <SystemMessage content={studentInstructions} />
          </>
        )}
        {messages?.map((message, index) => {
          if (!["user", "assistant"].includes(message.role)) {
            return null;
          }
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
              animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
              exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
              transition={{
                opacity: { duration: 0.1 },
                layout: {
                  type: "spring",
                  bounce: 0.3,
                  duration: messages.indexOf(message) * 0.05 + 0.2,
                },
              }}
              style={{
                originX: 0.5,
                originY: 0.5,
              }}
              className={cn(
                "flex flex-col gap-2 p-2",
                message.role === "user" ? "items-end" : "items-start",
              )}
            >
              <div className="flex items-center gap-3">
                {message.role === "assistant" && (
                  <Avatar
                    className="flex items-center justify-center"
                    seed={bot.seed}
                    title={bot.name}
                    initials="bot"
                    avatarClassName="h-10 w-10"
                  />
                )}
                <div
                  className={cn(
                    "prose prose-sm max-w-xl rounded-md p-3",
                    message.role === "assistant"
                      ? "bg-accent dark:prose-p:text-white"
                      : "bg-primary prose-p:text-white",
                  )}
                >
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {message.content}
                  </Markdown>
                </div>
                {message.role === "user" && (
                  <Avatar
                    className="flex items-center justify-center"
                    image={user.picture}
                    title={user.name}
                    initials="MM"
                    avatarClassName="h-10 w-10"
                  />
                )}
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

interface SystemMessageProps {
  content: string;
}

function SystemMessage({ content }: SystemMessageProps) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
      animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
      exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
      style={{
        originX: 0.5,
        originY: 0.5,
      }}
      className={cn("flex flex-col items-start gap-2 whitespace-pre-wrap p-2")}
    >
      <div className="flex items-center gap-3">
        <Avatar
          className="flex items-center justify-center"
          image="/logo-no-text.svg"
          initials="NC"
          avatarClassName="h-10 w-10"
          title="Nextgen Classroom"
        />
        <span className={cn("bg-accent max-w-xl rounded-md p-3")}>
          {content}
        </span>
      </div>
    </motion.div>
  );
}
