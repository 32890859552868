import Image from "next/image";

import { getBlurData } from "~/lib/blurhash";
import { type ImageItem } from "~/server/zod/word2";

interface Props {
  item: ImageItem;
  height: number;
  width: number;
  alt?: string;
  className?: string;
}

export function RenderWord2Image({
  item,
  height,
  width,
  alt,
  className,
}: Props) {
  const extraprops = item.blurhash
    ? ({
        placeholder: "blur",
        blurDataURL: getBlurData(item.blurhash),
      } as const)
    : {};
  return (
    <Image
      src={`/static/word2-image/${item.image}`}
      alt={alt ?? ""}
      width={width}
      height={height}
      className={className}
      {...extraprops}
    />
  );
}
