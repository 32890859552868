import { pgTableCreator, varchar } from "drizzle-orm/pg-core";
import { TypeID, typeid as typeid_org } from "typeid-js";
import { z } from "zod";

const maxLength = 72;

export const pgTable = pgTableCreator((name) => `merlin_${name}`);
export const pgTypeId = (name: string) =>
  // Not an UUID, but decodes to one
  varchar(name, {
    length: maxLength,
  });

export const typeIdString = z
  .string()
  .max(maxLength)
  .refine((x) => {
    try {
      TypeID.fromString(x);
      return true;
    } catch (e) {
      return false;
    }
  });

export function typeid(prefix: string) {
  return typeid_org(prefix).toString();
}
