"use client";

import { createContext, useContext } from "react";

import Twemoji from "~/components/ui/twemoji";
import { cn } from "~/lib/utils";
import { type TaskLanguages } from "~/server/cms/task-languages";
import { type Column } from "~/server/zod/word2";

const TaskLanguagesContext = createContext<TaskLanguages>({});

interface Props {
  value: TaskLanguages;
  children: React.ReactNode;
}

export const TaskLanguagesProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <TaskLanguagesContext.Provider value={value}>
      {children}
    </TaskLanguagesContext.Provider>
  );
};

export const useTaskLanguages = (): TaskLanguages => {
  const context = useContext(TaskLanguagesContext);

  if (context === undefined) {
    throw new Error(
      "useTaskLanguages must be used within a TranslationProvider",
    );
  }

  return context;
};

export function useWord2EmojiPicker() {
  const taskLanguages = useTaskLanguages();
  const items = Object.values(taskLanguages)
    .toSorted((a, b) => a.localizedName.localeCompare(b.localizedName))
    .map((lang) => ({
      id: lang.id,
      value: lang.id,
      label: `${lang.emoji} ${lang.localizedName}`,
    }));
  return items;
}

export function useWord2ColumnEmoji(column: Column) {
  const taskLanguages = useTaskLanguages();
  if (column.type === "word") {
    return taskLanguages[column.language]?.emoji ?? "🌐";
  }
  if (column.type === "image") {
    return "🖼️";
  }
  return "";
}

export function Word2ColumnEmoji({
  column,
  className,
}: {
  column: Column;
  className?: string;
}) {
  const emoji = useWord2ColumnEmoji(column);
  if (!emoji) {
    return null;
  }
  return (
    <Twemoji
      className={cn("inline-block", className)}
      height={24}
      width={24}
      emoji={emoji}
    />
  );
}
