import { z } from "zod";

import { type Prompt } from "~/payload-types";

export const sentenceBySentence = z.object({
  type: z.literal("sentenceBySentence").default("sentenceBySentence"),
  sentences: z.array(
    z.object({
      s: z.string().describe("Meningen som eleven skrev"),
      ok: z.boolean().describe("Var meningen korrekt?"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om meningen."),
    }),
  ),
  summary: z
    .string()
    .describe(
      "En sammanfattning av elevens prestation. Skriv som om du skulle skriva en kommentar till eleven",
    ),
});

export const sentenceBySentenceEnglish = z.object({
  type: z
    .literal("sentenceBySentenceEnglish")
    .default("sentenceBySentenceEnglish"),
  sentences: z.array(
    z.object({
      s: z.string().describe("Meningen som eleven skrev"),
      ok: z.boolean().describe("Var meningen korrekt?"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om meningen, skriv på engelska"),
    }),
  ),
  summary: z
    .string()
    .describe(
      "En sammanfattning av elevens prestation. Skriv som om du skulle skriva en kommentar till eleven på engelska",
    ),
});

export const paragraphByParagraph = z.object({
  type: z.literal("paragraphByParagraph").default("paragraphByParagraph"),
  sentences: z.array(
    z.object({
      s: z.string().describe("Stycket som eleven skrev"),
      ok: z.boolean().describe("Var stycket korrekt?"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om stycket, skriv på svenska."),
    }),
  ),
  summary: z
    .string()
    .describe("Skriv en sammanfattning om texten till eleven på svenska"),
});

export const paragraphByParagraphEnglish = z.object({
  type: z
    .literal("paragraphByParagraphEnglish")
    .default("paragraphByParagraphEnglish"),
  sentences: z.array(
    z.object({
      s: z.string().describe("Stycket som eleven skrev"),
      ok: z.boolean().describe("Var stycket korrekt?"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om stycket, skriv på engelska."),
    }),
  ),
  summary: z
    .string()
    .describe("Skriv en sammanfattning om texten till eleven på engelska"),
});

export const paragraphByParagraph2 = z.object({
  type: z.literal("paragraphByParagraph2").default("paragraphByParagraph2"),
  paragraphs: z.array(
    z.object({
      s: z.string().describe("Skriv ut hela stycket som eleven skrev"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om stycket, skriv på svenska."),
    }),
  ),
  summary: z
    .string()
    .describe("Skriv en sammanfattning om texten till eleven på svenska"),
});

export const paragraphByParagraph2English = z.object({
  type: z
    .literal("paragraphByParagraph2English")
    .default("paragraphByParagraph2English"),
  paragraphs: z.array(
    z.object({
      s: z.string().describe("Skriv ut hela stycket som eleven skrev"),
      c: z
        .string()
        .optional()
        .describe("Din kommentar till eleven om stycket, skriv på engelska."),
    }),
  ),
  summary: z
    .string()
    .describe("Skriv en sammanfattning om texten till eleven på engelska"),
});

export const functionCalls = {
  sentenceBySentence,
  sentenceBySentenceEnglish,
  paragraphByParagraph,
  paragraphByParagraphEnglish,
  paragraphByParagraph2,
  paragraphByParagraph2English,
} satisfies Record<Prompt["functionCall"], z.ZodTypeAny>;

export const functionCall = z.discriminatedUnion("type", [
  sentenceBySentence,
  sentenceBySentenceEnglish,
  paragraphByParagraph,
  paragraphByParagraphEnglish,
  paragraphByParagraph2,
  paragraphByParagraph2English,
]);
