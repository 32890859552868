"use client";

import FuzzySet from "fuzzyset";
import { useEffect, useMemo, useRef } from "react";
import { useWizard } from "react-use-wizard";
import { z } from "zod";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Progress } from "~/components/ui/progress";
import { extractSpecialChars } from "~/lib/strings";
import { type WordItem } from "~/server/zod/word2";

import { type LeftRightPair, isWordItem, useWord2TaskStore } from "./provider";

export function SpellStep({ word }: { word: LeftRightPair }) {
  const Store = useWord2TaskStore();
  const setAnswer = useStore(Store, (s) => s.setAnswer);
  const answers = useStore(Store, (s) => s.answers);
  const task = useStore(Store, (s) => s.computed.task);
  const ignoreWhitespace = useStore(Store, (s) => s.ignoreWhiteSpace);

  const answer = answers[word.id] ?? {
    id: word.id,
    data: {
      value: "",
      percentage: 0,
      done: false,
    },
  };

  const { nextStep } = useWizard();
  useEffect(() => {
    if (answer.data.done) {
      setTimeout(() => {
        void nextStep();
      }, 1000);
    }
  }, [answer.data, nextStep]);

  const specialChars = useMemo(() => {
    const allRightWords = task.pairs
      .flatMap((p) => p.right)
      .filter(isWordItem)
      .map((i) => i.word);
    return extractSpecialChars(allRightWords.join(" "));
  }, [task]);

  const rightWordItems = word.right.filter(
    (i) => i.type === "word",
  ) as WordItem[];

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (rightWordItems.length === 0) {
      return;
    }
    let right = rightWordItems.flatMap((i) =>
      i.word
        .trim()
        .split("\n")
        .map((w) => w.trim())
        .filter(Boolean),
    );
    let input = e.target.value.trim();
    if (ignoreWhitespace) {
      input = input.replace(/\s/g, "");
      right = right.map((w) => w.replace(/\s/g, ""));
    }
    const fuzzy = FuzzySet(right).get(input, undefined, 0);
    const percentage = fuzzy?.[0]?.[0] ?? 0;
    const done = percentage === 1;
    setAnswer({
      id: word.id,
      data: {
        ...answer.data,
        value: e.target.value,
        percentage,
        done,
      },
    });
  }

  const percentage = answer.data.percentage ?? 0;

  function handleShowAnswer() {
    setAnswer({
      id: word.id,
      data: {
        ...answer.data,
        wasShown: true,
      },
    });
  }

  function handleSpecialChar(char: string) {
    setAnswer({
      id: word.id,
      data: {
        ...answer.data,
        value: answer.data.value + char,
      },
    });
    inputRef.current?.focus();
  }

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="container max-w-[400px]">
      <div className="text-center">
        <Badge variant="secondary">{task.rightTitle}</Badge>
      </div>
      <div className="my-4">
        <Input
          ref={inputRef}
          autoFocus
          value={answer.data.value?.toString() ?? ""}
          onChange={handleChange}
          disabled={answer.data.done as boolean}
        />
        {specialChars.map((char) => (
          <button
            key={char}
            className="m-1"
            onClick={() => handleSpecialChar(char)}
          >
            <Badge variant="secondary">{char}</Badge>
          </button>
        ))}
      </div>
      {answer.data.wasShown && (
        <div className="text-center">
          <h2 className="py-2">
            {rightWordItems.flatMap((i) => i.word.split("\n")).join(", ")}
          </h2>
        </div>
      )}
      {answer.data.wasShown ? (
        <Button
          className="mx-auto"
          variant="outline"
          onClick={() => nextStep()}
        >
          <Translation id="app.task-word2.continue">Gå vidare</Translation>
        </Button>
      ) : (
        <div className="flex flex-row justify-between">
          <Button
            className="mx-auto"
            variant="outline"
            onClick={handleShowAnswer}
            disabled={answer.data.done as boolean}
          >
            <Translation id="app.task-word2.show-answer">
              Visa svaret
            </Translation>
          </Button>

          <Button className="mx-auto" variant="ghost">
            {answer.data.done ? "🎉" : `${floatToPercent(percentage)}%`}
          </Button>
        </div>
      )}
      <Progress
        className="text-secondary mt-2 w-full"
        value={Number(answer.data.percentage ?? 0) * 100}
      ></Progress>
    </div>
  );
}

function floatToPercent(f: unknown) {
  const p = z.coerce.number().min(0).max(1).parse(f);
  return Math.round(p * 100);
}
